// Same file in admin and site

export enum IFrameMessageType {
    Ready = "Ready",
    SelectComponent = "SelectComponent",
    OpenLink = "OpenLink",
    SitePreviewLocation = "SitePreviewLocation",
}

export interface IReadyIFrameMessage {
    cometType: IFrameMessageType.Ready;
}

export interface IFrameSelectComponentMessage {
    cometType: IFrameMessageType.SelectComponent;
    data: {
        adminRoute: string;
    };
}

export interface IFrameOpenLinkMessage {
    cometType: IFrameMessageType.OpenLink;
    data: {
        link: any; //TODO set correct type ExternalLinkBlockData
    };
}

export interface IFrameLocationMessage {
    cometType: IFrameMessageType.SitePreviewLocation;
    data: Pick<Location, "search" | "pathname">;
}

export type IFrameMessage = IReadyIFrameMessage | IFrameSelectComponentMessage | IFrameOpenLinkMessage | IFrameLocationMessage;

// Messages sent from Admin -> iFrame
export enum AdminMessageType {
    Block = "Block",
    SelectComponent = "SelectComponent",
    HoverComponent = "HoverComponent",
    AccessToken = "AccessToken",
}
export interface IAdminBlockMessage {
    cometType: AdminMessageType.Block;
    data: {
        block: unknown;
    };
}

export interface IAdminSelectComponentMessage {
    cometType: AdminMessageType.SelectComponent;
    data: {
        adminRoute: string;
    };
}

export interface IAdminHoverComponentMessage {
    cometType: AdminMessageType.HoverComponent;
    data: {
        adminRoute: string;
    };
}

export interface IAdminAccessTokenMessage {
    cometType: AdminMessageType.AccessToken;
    accessToken: string;
    apiUrl: string;
}

export type AdminMessage = IAdminBlockMessage | IAdminSelectComponentMessage | IAdminHoverComponentMessage | IAdminAccessTokenMessage;
