import * as React from "react";

export interface PreviewContextOptions {
    showPreviewSkeletons: boolean;
    // internal links only
    pathToPreviewPath: (originalPagePath: string) => string;

    // converts a previewpath to a path
    previewPathToPath: (previewPath: string) => string;
}

export const defaultPreviewContextValue: PreviewContextOptions = {
    showPreviewSkeletons: false,
    pathToPreviewPath: (path) => path,
    previewPathToPath: (previewUrl: string) => previewUrl,
};

export const PreviewContext = React.createContext<PreviewContextOptions>(defaultPreviewContextValue);
