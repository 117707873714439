import { GQLIFrameBridgePreviewPageQuery } from "@src/graphql.gatsby.generated";
import { PageContentBlock } from "@src/shared/cms/renderBlock";
import { BlockPreviewProvider } from "@src/skeleton/preview/BlockPreviewProvider";
import { IFrameBridgeProvider } from "@src/skeleton/preview/iframebridge/IFrameBridge";
import { useIFrameBridge } from "@src/skeleton/preview/iframebridge/useIFrameBridge";
import { graphql, PageProps } from "gatsby";
import * as React from "react";

const PreviewPage: React.FunctionComponent = () => {
    const iFrameBridge = useIFrameBridge();
    return <div>{iFrameBridge.block && <PageContentBlock data={iFrameBridge.block} />}</div>;
};
const IFrameBridgePreviewPage: React.FunctionComponent<PageProps<GQLIFrameBridgePreviewPageQuery>> = () => {
    return (
        <IFrameBridgeProvider>
            <BlockPreviewProvider>
                <PreviewPage />
            </BlockPreviewProvider>
        </IFrameBridgeProvider>
    );
};

export default IFrameBridgePreviewPage;

export const query = graphql`
    query IFrameBridgePreviewPage($leasemeScope: LeaseMe_ContentScopeInput!) {
        leaseme {
            allVehicles(scope: $leasemeScope) {
                facets {
                    ...AllFacets
                }
            }
        }
    }
`;
